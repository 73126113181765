import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import orgapi from '@orgmap/api' 
import boardapi from '@board/api'
import { mapGetters } from 'vuex';
import * as usertypes from '@account/store/types';

// component
import SearchForm from '@home/components/Search/SearchForm.vue'
import SearchResultBoard from '@home/components/Search/SearchResultBoard.vue'
import SearchResultOrg from '@home/components/Search/SearchResultOrg.vue'

@Component({
    components: {
        SearchForm,
        SearchResultBoard,
        SearchResultOrg
    },
    computed: mapGetters({
        userCurrent: usertypes.CURRENT
    })
})
export default class SearchContainer extends Vue {
    userCurrent!: account.TCurrent;
    totalCount: number = 0
    searchText: string = ''
    searchTarget: string = ''
    orgList: orgmap.UserItem[] = []
    orgCount: number = 0
    currentPage: number = 1
    pageSize: number = 5
    articleList: article.Article[] = []
    articleCount: number = 0
    userLang: string = ''

    fnTotalSearch(searchText: string, searchTarget: string){
        // 검색 시 데이터 초기화
        this.searchText = searchText;
        this.searchTarget = searchTarget;
        this.currentPage = 1;
        this.totalCount = 0;
        this.orgCount = 0;
        this.articleCount = 0;
        this.orgList = [];
        this.articleList = [];

        // 게시판 데이터 검색
        if (this.searchTarget == '' || this.searchTarget == 'board'){
            this.fnSearchArticle(this.currentPage, 5)
        }

        // 조직 데이터 검색
        if (this.searchTarget == '' || this.searchTarget == 'org'){
            var orgOption = {
                query: searchText,
                includeAllFlag: false,
                retiredFlag: false 
            } as orgmap.SearchOptions
            orgapi.hr.searchOrg(orgOption, this.userCurrent.company.comCode).then((res: any) => {
                const {items, totalCount} = res;
                if (items){
                    this.orgList = items;
                    this.orgCount = totalCount;
                    this.totalCount = totalCount + this.articleCount;
                }
            });
        }
    }

    // 게시글 검색
    fnSearchArticle(pageNum: number, pageSize: number){
        this.currentPage = pageNum;
        this.pageSize = pageSize
        // 게시판 데이터 검색
        var boardOption = {
            userCode: this.userCurrent.user.personCode,
            includeContent: true,
            useNotice: false,
            page: pageNum,
            itemsPerPage: pageSize,
            searchField: 30,
            searchFieldValue: this.searchText,
            sortField: 0,
            sortOrder: false
        } as article.TotalArticleSearchSet
        boardapi.article.getTotalArticles(boardOption).then((res: any) => {
            const { data, totalCount } = res;
            if (data){
                this.articleList = data;
                this.articleCount = totalCount;
                this.totalCount = this.orgCount + totalCount;
            }
        });
    }

    created(){
        // 사용자 다국어 key
        this.userLang = this.userCurrent.userConfig.language
        
        let reqSearch = (this.$route.query.SearchText ? this.$route.query.SearchText.toString() : '')
        let reqTarget = (this.$route.query.Target ? this.$route.query.Target.toString().toLowerCase() : '')

        if (reqSearch){
            this.fnTotalSearch(reqSearch, reqTarget);
        }
    }
}