import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
// import { WebPartPageCol } from './index'
// import WebPartPageCol from './WebPartPageCol.vue';

function add(accumulator: any, a: any) {
  return accumulator + a
}
@Component({
  name: 'WebPartPageRow',
  components: {
    WebPartPageCol: () => import('./WebPartPageCol.vue'),
  },
})
export default class WebPartPageRow extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  @Prop({ type: Object }) row!: appbase.cache.RowData
  @Prop({type: String}) templateName!: string;
  @Prop({type: Boolean, default : false}) isChildren!: boolean;

  

  created(){
    console.log(this.row)
     
  }
}
