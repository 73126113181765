import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class SearchResultOrg extends Vue {
    @Prop({type:Array}) orgList!: orgmap.UserItem[]
    @Prop({type:Number}) orgCount!: number
    @Prop({type:String}) userLang!: string

    displayDataCount: number = 4
    displayMore: boolean = false

    fnOpenMailForm(email: string){
    }

    fnMoreOrgData(){
        this.displayDataCount = 999
        this.displayMore = false
    }

    @Watch('orgList')
    fnChangeData(){
        this.displayDataCount = 4
        this.displayMore = (this.orgCount > this.displayDataCount ? true : false) 
    }
}