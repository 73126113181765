import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import api from '@home/api'
import { AttendanceType } from '@home/types/enum'
import * as dateHlper from '@appbase/util/date-helper'
import * as accountTypes from '@account/store/types'

@Component({
    computed: mapGetters({
         user: accountTypes.USER
    })
})
export default class AttendanceSideBar extends Vue {
    user!: account.TUser

    today : Date = new Date()
    
    isIn :boolean = false
    isOut :boolean = false

    attendanceInfo : home.attendance.ResponseAttendace | null = null


    created(){
        this.loadAttendance()
    }

    loadAttendance(){
        //current status 출근눌렀는지 , 퇴근 눌렀는지 
        //api 콜 이후 상태 입력해야될듯
        api.attendance.getAttendace().then(result=>{
            this.attendanceInfo = result

            if(result.inDateTime){
                this.isIn = true
            }

            if(result.outDateTime){
                this.isOut = true
            }
        })
    }

    async onClickedBtn(param:string){
        // 출근 체크 가능 시간 유효성 검사(00시 ~ 05시 출근 체크 불가능)
        // 05시 이전 퇴근 시 전일 퇴근 적용 알림
        var dtNow = new Date();
        if (param === 'in' && dtNow.getHours() < 5){
            await (window as any).alertAsync(this.$t('출근_가능시간_알림').toString());
            return
        }
        if (param === 'out' && dtNow.getHours() < 5){
            var confirmMsg = this.$t('전일퇴근적용_알림').toString();
            if (!await(window as any).confirmAsync(confirmMsg)) return;
        }

        //param : in 출근
        //param : out 퇴근 
        if(param === "in" && this.isIn == true  ){
            //이미 완료 처리 건
            //const displayTime =   dateHlper.uGetDateFormat_UTC("yyyy-MM-DD H:i:s",new Date(this.attendanceInfo?.inDateTime))
            const displayTime = dateHlper.uGetDateFormat("yyyy-MM-DD H:i:s",new Date(this.attendanceInfo?.inDateTime));
            await (window as any).alertAsync(this.$t('이미_출근처리_되었습니다').toString() + '(' + displayTime + ')');
            //alert(`이미 완료처리되었습니다.\n출근시간 : ${displayTime}`)
            return
        }

        if(param === "out" && this.isOut == true  ){
            //이미 완료 처리 건
            //const displayTime =   dateHlper.uGetDateFormat_UTC("yyyy-MM-DD H:i:s",new Date(this.attendanceInfo?.outDateTime))
            const displayTime = dateHlper.uGetDateFormat("yyyy-MM-DD H:i:s",new Date(this.attendanceInfo?.outDateTime));
            await (window as any).alertAsync(this.$t('이미_퇴근처리_되었습니다').toString() + '(' + displayTime + ')');
            //alert(`이미 완료처리되었습니다.\n퇴근시간 : ${displayTime}`)
            return
        }

        if (param === "in"){
            // 출근일 경우 등록된 근무시간 설정 여부를 확인한다.
            api.attendance.getStaggerRegistCheck(this.user.personCode)
                          .then(async res => {
                if (res > 0){
                    this.attendanceProcess(param);
                }
                else{
                    await (window as any).alertAsync(this.$t('등록된_근무시간이_없습니다').toString());
                }
            });
        }
        else{
            this.attendanceProcess(param);
        }        
    }

    async attendanceProcess(param:string){
        if(!await(window as any).confirmAsync(this.$t('출_퇴근_처리를_진행하시겠습니까').toString())) return

        api.attendance.createAttendance({
            AttendanceType : param ==="in" ? AttendanceType.in : AttendanceType.out,
            PersonCode: this.user.personCode,
            DisplayName: this.user.displayName,
            DisplayDeptName: this.user.deptName,
            DisplayTitleName: this.user.titleName
            // attendanceDatetime : new Date() 
        }).then(async result=>{
            let aType = param ==="in" ? this.$t('W_Hours_Attendance') : this.$t('W_Hours_Closing')

            if(result== true){
                var msg = (param == 'in' ? this.$t('출근_하였습니다').toString() : this.$t('퇴근_하였습니다').toString());
                await (window as any).alertAsync(msg);
            }else{                
                //alert(`${aType} 기록 작업이 실패하였습니다.`)
                await (window as any).alertAsync(this.$t('M_ErrorMsg_TaskProcess_04').toString());
            }
        }).finally(()=>{
            this.loadAttendance()
        })
    }

    //나라별 표기법이 다르겠지?
    //한국 기준으로만 우선 표시
    ConertDatetimeByLangCode(){
        //국각코드 user 속성에 있을거니깐
        const langCode = "ko-kr" 

        const weekOfDays = [
            { value : 0, kor : "일"},
            { value : 1, kor : "월"},
            { value : 2, kor : "화"},
            { value : 3, kor : "수"},
            { value : 4, kor : "목"},
            { value : 5, kor : "금"},
            { value : 6, kor : "토"},
        ]

        const weekOfDay = weekOfDays.find(item=>{
            return item.value == this.today.getDay()
        })?.kor

        //2022-06-13 월 -1 되서 표시됨 +1 추가
        return `${this.today.getMonth()+1}월 ${this.today.getDate()}일 (${weekOfDay})`
    }
}
