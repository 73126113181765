import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import api from '@home/api'

// export interface homeWebpartCount {
//     scheduleCount: number
//     workflowCount: number
//     unreadMailCount: number
//     todoCount: number
// }
export enum GoTo{
    calendar = "calendar",
    workflow = "workflow",
    proccesingWorkflow= "proccesing",
    mail = "mail",
    todo = "todo"
}


@Component
export default class MyCountWebpart extends Vue {
    counts: home.homeWebpartCount = {
        todoCount: 0,
        scheduleCount: 0,
        unreadMailCount: 0,
        workflowCount: 0,
        proccesingWorkflowCount : 0
    }


    created() {
        // //카운트별 초기화 진행
        // api.home.getUnreadMailCount().then(result=>{
        //     //아직 안되는듯?
        // })
        let offset = new Date().getTimezoneOffset()
        //일정 메일 카운트 현재 불가함
        api.home.getCount(offset).then(result => {
             
            this.counts = result
        })

    }

    onClickedIcon(value: GoTo){
         
        //메뉴아이디가 다 하드코딩되어있네? 아마 메뉴 아이디가 있어야 좌측 메뉴 보여줄수 있음
        let routePath = ""
        if(value == GoTo.calendar){
            routePath = "/po/home/proxy?MENUID=1105"
        }
        else if(value == GoTo.mail){
            routePath =  "/po/home/proxy?MENUID=4"
        }else if(value== GoTo.workflow){
            routePath =  "/workflow/page/documentlist?dqn=1&MENUID=9&SYSID=1"
        }else if(value== GoTo.proccesingWorkflow){
            routePath =  "/workflow/page/documentlist?dqn=1007&MENUID=1082"
        }
        else if(value== GoTo.todo){
            routePath = "https://to-do.office.com"
        }
        
        //this.$router 에서 window.open 으로 변경
        //window.open -> window.location.href 로 변경
        window.location.href = routePath
        // window.open(routePath)
    }
}
