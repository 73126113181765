import api from '@/api'
 
interface ResponseWrapper<TResult> {
    result: TResult
    code: string
    message: string
    error: string | null
  }


  
export default {
  getTodayNoticePopupList(comCode: string) : Promise<Array<noticepopup.NoticeItem>>{
    return api.get({
      url: `/Common/NoticePopup/api/list/${comCode}`
    });
  },

  //#region 카운트 관련

  //읽지 않은 메일 카운트
  //timezoneoffset param 추가 //일정 정보 조회시 필요함
  getCount(offset:number): Promise<home.homeWebpartCount>{
    return api.get({
      url: `/api/z/homewebpart/count`,
      params: {
        offset : offset
      }
    })
  }
  //#endregion
}