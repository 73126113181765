<template>
  <div id="inner-wrap" class="full-content">
    <div class="mypage-wrap">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
