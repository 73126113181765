import api from "@/api"

export default {
    // 하위 게시글 목록 조회
    getRecentSubArticleList(menuId: number, comCode: string, personCode: string, 
                            page: number, pageSize: number, searchType: number, searchText: string){
        return api.get({
            url: `/api/BoardApi/Article/subrecentarticlelist?MenuId=${menuId}&ComCode=${comCode}&PersonCode=${personCode}&Page=${page}&ItemsPerPage=${pageSize}&SearchField=${searchType}&SearchText=${searchText}`,
        })
    },

    getTickers(param : home.tickerRequest) : Promise<Array<home.tickerItem>>{
        return api.get({
            url: `/api/z/ticker/list`,
            params : param
        })
    }
}