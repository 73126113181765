import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import MyInfoWebpart from '@home/components/FixedWebpart/MyInfoWebpart.vue'
import MyCountWebpart from '@home/components/FixedWebpart/MyCountWebpart.vue'
import NoticeTicker from '@home/components/FixedWebpart/NoticeTicker.vue'

@Component({
    components:{
        MyCountWebpart, MyInfoWebpart,NoticeTicker 
    },
    computed: mapGetters({
         
    })
})
export default class FixedWebpartContainer extends Vue {
    
}
