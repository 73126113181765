import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'
import { openPopup } from '@appbase/util/popup-helper'
import modalHelper from '@appbase/util/modal-helper';
import ZinusBoardModal from "@webparts/views/Zinus/Modal/ZinusBoardModal.vue"

@Component({
    components: { PageNavigationBar }
})
export default class SearchResultBoard extends Vue {
    @Prop({ type: Array }) articleList!: article.Article[]
    @Prop({ type: Number }) articleCount!: number
    @Prop({ type: Number }) currentPage!: number
    @Prop({ type: Number }) pageSize!: number
    @Prop({ type: String }) userLang!: string

    displayMore: boolean = false

    // PageNavigation Click Event
    fnMovePage(number: number) {
        this.$emit("onPaging", number, this.pageSize);
    }

    fnVieArticle(articleId: number, boardId: number, title: string) {

        if (this.isTeams) {
            modalHelper.show({ component: ZinusBoardModal, force: true, value: { boardId: boardId, articleId: articleId, title: title } });
        }
        else {
            openPopup('', `/BoardPopup/readPopup?SYSID=${boardId}&ARTICLEID=${articleId}`, {}, {
                width: 1024,
                height: 700,
            });
        }


    }

    fnDisplayDate(date: Date) {
        let dtDate = new Date(date);
        return dtDate.toISOString().slice(0, 10);
    }

    fnMoreBoardData() {
        this.displayMore = false
        this.$emit("onPaging", 1, 10);
    }

    @Watch('articleList')
    fnChageArticleList() {
        this.displayMore = (this.pageSize == 5 && this.articleCount > this.pageSize ? true : false)
    }
}