import api from '@/api'

export default {
  getMypageArticle: (data:mypage.mypageArticleReq): Promise<mypage.mypageArticleRes> => {
    return api.post({
        url: '/api/Z_MyPage/myarticle',
        data : data

    });
},
}