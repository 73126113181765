import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { open, OpenType } from '@board/open-handler';
import * as Boardtypes from '@board/store/types'
import { mapGetters } from 'vuex';
import menuHelper from '@appbase/util/menu-helper'
import { LoadingBar } from '@appbase/components/loading'


@Component({
    components: { LoadingBar },
    computed: mapGetters({
        popupBaseUrl: Boardtypes.POPUP_BASEURL,
    }),
})
export default class MypageList extends Vue {
    @Prop({type:Array}) articles!:Array<mypage.mypageArticle>
    @Prop({type:Number, default : 0 }) total!:Number
    @Prop({type:Number, default : 0 }) listType!:Number
    @Prop({type:Boolean, default : 0 }) isLoading!:Number

    popupBaseUrl!: string;

    goDetailPage(article: mypage.mypageArticle) {
          open(this.isTeams ?
            OpenType.ModalRead : OpenType.PopupRead,
                {
                    boardId: article.boardId,
                    articleId: article.articleId,
                    baseUrl: this.popupBaseUrl
                });
    }
    
    goMenu(boardId: number) {
        var tar = menuHelper.findOneBySysId(boardId,'BB')
        if(tar)
        menuHelper.next(tar)
    }
}
