var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"part-tab"},[_c('div',{staticClass:"p-head"},[_c('div',{staticClass:"dp-flex"},[_c('div',{staticClass:"mr-auto"},[_c('ul',{staticClass:"nav nav-tabs tab-text"},[_c('li',{staticClass:"active"},[_c('a',{staticClass:"ft-basic",attrs:{"href":"#tab1"}},[_vm._v(_vm._s(_vm.$t('내가_작성한_글')))])]),_c('li',[_c('a',{staticClass:"ft-basic",attrs:{"href":"#tab2"}},[_vm._v(_vm._s(_vm.$t('내가_작성한_댓글')))])])])]),_c('div',{staticClass:"ml-auto"},[_c('button',{staticClass:"btn sub-btn btn-basic-outline"},[_vm._v(_vm._s(_vm.$t('W_Delete')))]),_c('button',{staticClass:"btn sub-btn btn-basic-outline"},[_vm._v(_vm._s(_vm.$t('W_BB_Write')))])])])]),_c('div',{staticClass:"p-body"},[_c('div',{staticClass:"space-m"}),_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane active",attrs:{"id":"tab1"}},[_c('div',{staticClass:"grd-wrap"},[_c('div',{staticClass:"grd-head"},[_c('table',{staticClass:"grd",attrs:{"width":"100%"}},[_vm._m(0),_c('thead',[_c('tr',[_vm._m(1),_c('th',[_vm._v(_vm._s(_vm.$t('게시판')))]),_c('th',[_vm._v(_vm._s(_vm.$t('제목')))]),_c('th',{staticClass:"align-center"},[_vm._v(_vm._s(_vm.$t('작성일시')))])])])])]),_c('div',{staticClass:"grd-body"},[_c('table',{staticClass:"grd",attrs:{"width":"100%"}},[_vm._m(2),_c('tbody',[_c('tr',[_vm._m(3),_c('td',[_vm._v("Q&A")]),_c('td',[_c('a',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t('타임시트_제출버튼_처리관련')))])]),_c('td',{staticClass:"align-center"},[_vm._v("2021-02-21")])]),_c('tr',[_vm._m(4),_c('td',[_vm._v(_vm._s(_vm.$t('자료실')))]),_c('td',[_c('a',{staticClass:"link"},[_vm._v(_vm._s(_vm.$t('회사소개서_솔루션소개서_업데이트')))])]),_c('td',{staticClass:"align-center"},[_vm._v("2021-03-26")])])])])])]),_c('div',{staticClass:"space-m"}),_vm._m(5)]),_c('div',{staticClass:"tab-pane",attrs:{"id":"tab2"}})])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('colgroup',[_c('col',{attrs:{"width":"40px"}}),_c('col',{attrs:{"width":"80px"}}),_c('col'),_c('col',{attrs:{"width":"120px"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('th',{staticClass:"align-center"},[_c('input',{attrs:{"type":"checkbox"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('colgroup',[_c('col',{attrs:{"width":"40px"}}),_c('col',{attrs:{"width":"80px"}}),_c('col'),_c('col',{attrs:{"width":"120px"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"align-center"},[_c('input',{attrs:{"type":"checkbox"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('td',{staticClass:"align-center"},[_c('input',{attrs:{"type":"checkbox"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pagination-wrap"},[_c('div',{staticClass:"align-left"},[_c('span',{staticClass:"ft-gray ft-s"},[_vm._v("1 - 10 of 100 items")])]),_c('div',{staticClass:"align-center"},[_c('ul',{staticClass:"pagination"},[_c('li',[_c('a',{staticClass:"page-link frist disabled"},[_c('i',{staticClass:"icon-fux-arrow-first-bold"})])]),_c('li',[_c('a',{staticClass:"page-link prev disabled"},[_c('i',{staticClass:"icon-fux-arrow-left-line-bold"})])]),_c('li',[_c('a',{staticClass:"page-link"},[_vm._v("1")])]),_c('li',[_c('a',{staticClass:"page-link active"},[_vm._v("2")])]),_c('li',[_c('a',{staticClass:"page-link"},[_vm._v("3")])]),_c('li',[_c('a',{staticClass:"page-link"},[_vm._v("4")])]),_c('li',[_c('a',{staticClass:"page-link"},[_vm._v("5")])]),_c('li',[_c('a',{staticClass:"page-link next"},[_c('i',{staticClass:"icon-fux-arrow-right-line-bold"})])]),_c('li',[_c('a',{staticClass:"page-link last"},[_c('i',{staticClass:"icon-fux-arrow-last-bold"})])])])]),_c('div',{staticClass:"align-right"})])
}]

export { render, staticRenderFns }