import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';

import apiclient from '@/api';
import api from '@appbase/api'
import { mapGetters, mapMutations } from 'vuex';
import * as appbaseTypes from '@appbase/store/types'
import * as types from '@home/store/types'
import { createEventBus, getDispatchData, removeEventBus } from '@appbase/util';
const EumCookiePrefix = ".EumDoNotPopup_"

@Component({
    components: {

    },
    computed: {
        ...mapGetters({
            isTeams: appbaseTypes.TEAMS,
            modalPopupItem: types.NOTICEPOPUP_MODAL_GET,
            getPopupItems: types.NOTICEPOPUP_GET,
        })
    } ,
    methods:{
        ...mapMutations({
            setModalPopupItem: types.NOTICEPOPUP_MODAL_SET,
            setPage: appbaseTypes.PAGE_CURRENT
        })
    }
})
export default class NoticePreviewView extends Vue {

    // mutations
    setModalPopupItem!: (data: noticepopup.ModalNoticeItem) => void

    // getters
    isTeams!: boolean
    popupItem!: noticepopup.NoticeItem | noticepopup.ModalNoticeItem
    modalPopupItem!: noticepopup.ModalNoticeItem

    //data
    isChecked: boolean = false
    getPopupItems!: Array<noticepopup.NoticeItem>
    curIdx : number =0
    modalLast:boolean =false
    
    NOTICE_PREVIEW_POPUP: string = '$notice/popup/preview';

    created() {
        if (this.isTeams == false) {
            createEventBus(window, this.NOTICE_PREVIEW_POPUP, this.handlePopupItem);
            
        } else { //팀즈인경우
            if(this.getPopupItems){
                this.setModalPopupItem({
                    ...this.getPopupItems[0],isShow:true
                })
                this.modalLast = this.getPopupItems.length==1
            }
            this.popupItem = this.modalPopupItem
        }

    }
    handlePopupItem() {
        const jsonData = getDispatchData(this.NOTICE_PREVIEW_POPUP)
        const objData = JSON.parse(jsonData)
        // console.log('handlePopupItem', jsonData, objData)
        if (objData) {
            //Created 라이프사이클에서는 ref 로 iframe 에 접근 할 수 없는 시점 데이터만 처리          
            this.popupItem = objData

            //브라우저인경우만 타이틀 변경
            if(this.isTeams == false) {
                window.document.title = this.popupItem.title
                this.setPage({ title: this.popupItem.title, full: false});
            }
        }
    }

    mounted() {
        //dom 그려지는 시점에서 데이터 입력
        setTimeout(() => {
            this.loadContent()
        }, 100)
    }

    beforeDestroy(){
        removeEventBus(window, this.NOTICE_PREVIEW_POPUP, ()=>{});
        removeEventBus(window.opener, this.NOTICE_PREVIEW_POPUP, ()=>{});
    }

    loadContent(){
        if (this.popupItem) {
            
            

            let iframe = <HTMLIFrameElement>this.$refs.iframeRef;

            if (this.popupItem.noticePopupType == 1) {
                if (iframe.contentDocument) {
                    iframe.contentDocument.body.innerHTML = this.popupItem.noticePopupContents
                }
            } else {
                //this.popupItem.fileCode

                api.file.getFiles(this.popupItem.fileCode).then(result => {
                    let imgUrl = ""
                    if (result.length > 0) {
                        imgUrl = result[0].downloadUrl
                    } else {
                        // C:\NAS\gres\images\img_not_found.jpg

                        imgUrl = "/cm/gres/images/img_not_found.jpg"
                    }

                    if (iframe.contentDocument) {
                        iframe.contentDocument.body.innerHTML = `<img style='width:100%;height:100%' src='${imgUrl}' />`
                    }
                })
                //let imgEle = `<img src="${this.popupItem.}"`
            }


        }
    }
    close() {
        window.close()
    }
    /**
     * 관리자 페이지에서는 대상아님
     */
    @Watch("isChecked")
    onChangedCheckedOption() {
        if(this.isChecked == true){
            apiclient.get({
                url: '/api/z/noticepopup/notopen?pid=' + this.popupItem?.noticePopupId + '&expired=false',
            });
        }
        else{
            apiclient.get({
                url: '/api/z/noticepopup/notopen?pid=' + this.popupItem?.noticePopupId + '&expired=true',
            });
        }
    }

    @Watch('curIdx')
    setCurModalPopupItem(val:number, bfval:number){
        this.modalLast = this.curIdx == this.getPopupItems.length-1
        this.setModalPopupItem({
            ...this.getPopupItems[bfval],isShow:false
        })
        this.setModalPopupItem({
            ...this.getPopupItems[val],isShow:true
        })
        this.popupItem = this.modalPopupItem
        this.loadContent()
    }
}
