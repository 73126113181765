import api from '@/api'
 

  
export default {
  createAttendance(data: any) : Promise<any>{
    return api.post({
      url: `/api/z/attendance`,
      data : data
    });
  },

  getAttendace() : Promise<home.attendance.ResponseAttendace>{
    return api.get({
        url: `/api/z/attendance`
      });
  },

  // 근무시간 설정 여부 확인
  getStaggerRegistCheck(personCode: string) : Promise<number>{
    return api.get({
      url: `/api/z/stagger/StaggerRegistCheck?personCode=${personCode}`
    })
  }
}