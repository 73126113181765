import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import FullMasterTemplate from '@appbase/templates/FullMasterTemplate.vue'
import { LeftMenu } from '@home/components/MyPage'
import MypageContainer from '@home/containers/MypageContainer/MypageContainer.vue'

@Component({
    components: { FullMasterTemplate, LeftMenu, MypageContainer },
})
export default class MyPageListView extends Vue {

    listType : number =0

    loadList(val:number){
        this.listType = val
    }

}
