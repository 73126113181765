import Vue from 'vue';
import { Component } from 'vue-property-decorator';


export enum CurPage {
    article = 0,
    comment = 1,
    recentView = 2,
}

@Component({
    components: {  },
})
export default class MypageLeftMenu extends Vue {
    selected:CurPage = CurPage.article

    menuClick(target:number){
        this.selected = target;
        this.$emit('loadList',target)
    }
}
