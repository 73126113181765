import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'
import { openPopup } from '@appbase/util/popup-helper'
import api from '@home/api'

// component
import { FullMasterTemplate } from '@/modules/@appbase/templates'
import { LeftMenu } from '@appbase/components/common'
import RecentArticleList from '@home/components/RecentArticle/RecentArticleList.vue'

@Component({
    components: { FullMasterTemplate, LeftMenu, RecentArticleList },
    computed: {
        ...mapGetters({
          current: typesAccount.CURRENT,
        }),
    },
})
export default class RecentArticleView extends Vue {
    current!: account.TCurrent

    articleItems: home.article.ArticleItem[] = [];
    currentPage: number = 1
    pageSize: number = 10
    totalPageCount: number = 0;
    searchType: number = 0
    searchText: string = ''
    userLang: string = ''
    menuId: number = 0

    @Watch('$route.query.MENUID')
    fnChangeMenuId(){
        // 메뉴 title 설정
        const { MENUID } = this.$route.query;
        this.menuId = (MENUID ? Number(MENUID) : 0);

        // 변수 초기화
        this.articleItems = [];
        this.currentPage = 1;
        this.pageSize = 10;
        this.totalPageCount = 0;
        this.searchType = 0
        this.searchText = ''

        // 게시글 데이터 조회
        this.fnGetRecentArticleList();
    }

    fnPaging(pageNum: number){
        this.currentPage = pageNum;
        this.fnGetRecentArticleList();
    }

    fnArticleSearch(searchType: number, searchText: string){
        this.searchType = searchType
        this.searchText = searchText
        this.currentPage = 1
        this.articleItems = []
        this.totalPageCount = 0

        this.fnGetRecentArticleList()
    }   

    fnArticleView(article: home.article.ArticleItem){
        openPopup('ListImageWebpart', `/BoardPopup/readPopup?SYSID=${article.boardId}&ARTICLEID=${article.articleId}`, {}, {
            width: 1024,
            height: 700,
        });
    }

    fnGetRecentArticleList(){        
        if (this.menuId > 0){
            api.board.getRecentSubArticleList(this.menuId, this.current.user.comCode, this.current.user.personCode, 
                                                this.currentPage, this.pageSize, this.searchType, this.searchText).then((res: any) => {
                if (res) {
                    this.articleItems = res.data;
                    this.totalPageCount = res.totalCount;
                }
                else{
                    this.articleItems = []
                    this.totalPageCount = 0
                }
            });
        }
    }

    created(){
        this.userLang = this.current.userConfig.language.toLowerCase();
        this.fnChangeMenuId();
    }
}
