import Vue from 'vue';
import { Component } from 'vue-property-decorator';
@Component({
    components: {  },
})
export default class MypageHeader extends Vue {

    onClickSearch() {
        var inpElem = this.$refs.refInpSearch as HTMLInputElement;
        this.$emit('searchClickCallback', inpElem.value);
    }

    reset(){
        var inpElem = this.$refs.refInpSearch as HTMLInputElement;
        inpElem.value='';
        this.$emit('reset')
    }
}
