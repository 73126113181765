import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'
//import {ListTabWebpart, CalendarWebpart, ListSummaryWebpart, ListSuveyWebpart} from '@webparts/views/index';
// const ListTabWebpart = () => import("@webparts/views/ListTabWebpart/ListTabWebpart.vue");
// const CalendarWebpart = () => import("@webparts/views/CalendarWebpart/CalendarWebpart.vue");
// const ListSummaryWebpart = () => import( "@webparts/views/ListSummaryWebpart/ListSummaryWebpart.vue");
// const ListSuveyWebpart = () => import("@webparts/views/ListSuveyWebpart/ListSuveyWebpart.vue");

@Component({
  name: 'WebPartPageCol',
  components: {
    WebPartPageRow: () => import('./WebPartPageRow.vue'),
  },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class WebPartPageCol extends Vue {
  //
  // ────────────────────────────────────────────────── I ──────────
  //   :::::: P R O P S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────
  //
  @Prop({ type: Array }) rows?: appbase.cache.RowData[]
  @Prop({ type: Number }) colWidth?: number
  @Prop({ type: Object }) webPartItem?: appbase.cache.TWebPartItem
  @Prop({ type: String }) templateName!: string
  @Prop({type: Boolean, default : false}) isChildren!: boolean;
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  current!: account.TCurrent

  //
  // ──────────────────────────────────────────────────────── I ──────────
  //   :::::: C O M P U T E D : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────
  //
  get classInfo() {
    return `col-${this.colWidth}`
  }

  get myStyle() {
    const { rows, webPartItem } = this
    const h = rows ? 0 : (webPartItem as any).h
    if (h > 0) {
      return `height: ${h * 400}px;`
    } else {
      return ''
    }
  }

  get templateType() {
    // if (this.templateName == 'MyPage') {
    //   return 'wpart-template3'
    // } else {
    //   // 포털 + 달력인경우
    //   if (this.webPartItem?.categoryId == 5) {
    //     return 'wpart-template'
    //   } else {
    //     return 'wpart-template1'
    //   }
    // }
    return 'wpart-template'
  }

  get webPartHeight() {
    var width = this.getAppConfig('WebPartHeight')
    return `height: ${Number(width) * this.webPartItem!.h}px; `
  }

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  getWepartView(categoryId: number) {

    if (!this.getAuthCheck()) return
    switch (categoryId) {
      case 1: // 단순목록
        return require('@webparts/views/ListDefaultWebpart/ListDefaultWebpart.vue').default
      case 2: // 탭 목록
        return require('@webparts/views/ListTabWebpart/ListTabWebpart.vue').default
      case 5: // 달력 + 일정
        return require('@webparts/views/CalendarWebpart/CalendarWebpart.vue').default
      case 7: // 사용자정보
        return require('@webparts/views/UserInfoWebpart/UserInfoWebpart.vue').default
      case 11: // 설문조사
        return require('@webparts/views/ListSuveyWebpart/ListSuveyWebpart.vue').default
      case 13: // 목록-요약
        return require('@webparts/views/ListSummaryWebpart/ListSummaryWebpart.vue').default
      case 14: // 목록-사진
        return require('@webparts/views/ListImageWebpart/ListImageWebpart.vue').default
      case 1024: // 목록-내게시물
        return require('@webparts/views/MyArticleWebpart/MyArticleWebpart.vue').default
      case 16: // 결재 탭
        return require('@webparts/views/ApproveWebpart/ApproveWebpart.vue').default
      case 18: // 결재, 받은메일함 갯수
        return require('@webparts/views/ApproveMailWebpart/ApproveMailWebpart.vue').default
      case 19: // 받은메일함
        return require('@webparts/views/InboxWebpart/InboxWebpart.vue').default
      case 21: // 통합 게시판
        return require('@webparts/views/TotalBoardWebpart/TotalBoardWebpart.vue').default
      case 22: // 목록-사진(스와이프)
        return require('@webparts/views/ListImageSwipeWebpart/ListImageSwipeWebpart.vue').default
      case 23: // 미결함
        return require('@webparts/views/PendingBoxWebpart/PendingBoxWebpart.vue').default
      case 1023: // 사용자프로파일
        return require('@webparts/views/UserProfileWebpart/UserProfileWebpart.vue').default
      case 1025: // 바로가기
        return require('@webparts/views/ShortCutWebpart/ShortCutWebpart.vue').default
 
        //#region 지누스
        case 3001: // 지누스 일정
        return require('@webparts/views/Zinus/Default/ZinusCalendarWebpart.vue').default
        case 3002: // 지누스 공지
        return require('@webparts/views/Zinus/Default/ZinusNoticeWebpart.vue').default
        case 3003: // 지누스 법인소식
        return require('@webparts/views/Zinus/Default/ZinusCompanyNews.vue').default
        case 3004: // 지누스 바로가기
        return require('@webparts/views/Zinus/Default/ZinusShortCutWebpart.vue').default
        case 3005: // 지누스 세계시간
        return require('@webparts/views/Zinus/Default/ZinusWorldTimeWebpart.vue').default
        //#endregion 지누스
    }
  }

  getAuthCheck() {
    if (!this.webPartItem?.auth) {
      return true
    }

    var webPartAuth = this.webPartItem?.auth?.split(',')

    if (webPartAuth!.length == 0) {
      return true
    }

    var isAuth = false
    for (var i = 0; i < webPartAuth!.length; i++) {
      for (var j = 0; j < this.current.permissions.roles.length; j++) {
        if (webPartAuth![i] == this.current.permissions.roles[j].id.toString()) {
          isAuth = true
          break
        }
      }
    }

    return isAuth
  }

  // randomColor(){

  //     return (Math.random() * 0xffffff) << 0;
  // }
}
