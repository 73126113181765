import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import * as cacheType from '@/store/types'
import { WebPartPageDiv } from '@/modules/home/components/WebPartPage/index'
import menuHelper from '@/modules/@appbase/util/menu-helper'
import { MenuCallTypes } from '@/modules/@appbase/types/enum'
@Component({
  components: {
    WebPartPageDiv,
  },
})
export default class WebpartContainer extends Vue {
  @Prop({ type: String }) templateName!: string

  div: appbase.cache.WebPartDiv = {
    rows: [Object.create({})],
  }

  //
  // ──────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E S : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────────────────
  //

  mounted() {
    const { name } = this.$route
    console.log('111', name)
    var menuid = Number(this.$route.query.MENUID)
    if (name == 'Home') {
      const homeMenu = menuHelper.findOneByCalltype(MenuCallTypes.HOME)
       
      if (homeMenu) {
        menuid = homeMenu.menuId
      }
    } else if (name === 'MyPage') {
      const mypageMenu = menuHelper.findOneByCalltype(MenuCallTypes.MYPAGE)
      console.log('222', menuHelper.cache.menu)
      if (mypageMenu) {
        menuid = mypageMenu.menuId
      }
    }
    const webpart = this.portalCache.webparts.find((x) => x.menuId === menuid)
    if (webpart) {
      this.div = webpart.div
    } else {
      throw new Error('[INVALID_OPERATION_ERRROR] not found the home page menu.')
    }
  }
}
