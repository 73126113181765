import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { MyPageTemplate } from '../templates'
import { WebpartContainer } from '../containers'
import api from '../api/index'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'

@Component({
  components: { MyPageTemplate, WebpartContainer },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class WebPartView extends Vue {
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  current!: account.TCurrent

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
  mounted() {
    
  }
}
