import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { Dictionary } from 'vue-router/types/router'
import { LoadingBar } from '@appbase/components/loading'

@Component({
  components:{
    LoadingBar
  }
})
export default class ContentComponent extends Vue {
  @Prop({ type: Object }) item!: appbase.cache.TMenuItem
  @Prop({ type:Boolean }) isUseLeftMenu!: boolean
  //
  // ────────────────────────────────────────────────────────────── I ──────────
  //   :::::: L O C A L   S T A T E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────
  //

  loadingIframe : boolean = true
  isUseIframe: boolean = false
  src: string | null = null

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E C Y C L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
  @Watch('item')
  fnSetContent(){
    const { useIframe, frameUrl, baseUrl, menuId, systemId } = this.item
 
    if (frameUrl){
      this.isUseIframe = useIframe
      var query: Dictionary<any> = { MENUID: menuId }  
      if (systemId) query.SYSID = systemId
      let hrefUrl = frameUrl;
 
      // frameUrl이 내부인 경우
      if (!(/^(.*:)\/\/([A-Za-z0-9\-\.]+)(:[0-9]+)?(.*)$/.test(frameUrl))){
         
        hrefUrl += (frameUrl.indexOf('?') >= 0 ? '&' : '?') +  'MENUID=' + menuId + (systemId ? '&SYSID=' + systemId : '');
        if (process.env.NODE_ENV === 'production'){
          // 운영 환경인 경우 base url을 붙여준다.
          hrefUrl = baseUrl + hrefUrl;
        }

        if (useIframe){
          // iframe인 경우
          this.src = hrefUrl;
        }
        else{
          this.$router.push({
            path: frameUrl,
            query: query
          })
        }
      }
      else{
        // 외부 URL인 경우 처리
        if (useIframe){
          this.src = frameUrl;
        }
        else{
          document.location.href = frameUrl;
        }
      }
    }
    else {
      throw new Error('[NOT_IMPLEMENTED_ERROR] : targetURL is empty.')
    }
  }

  created() {
    this.fnSetContent();

    
  }

 
}
