import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
// import { WebPartPageRow } from './index'
// import WebPartPageRow from './WebPartPageRow.vue'

@Component({
    name: "WebPartPageDiv",
    components: {
        WebPartPageRow: () => import('./WebPartPageRow.vue')
    },
})
export default class WebPartPageDiv extends Vue {
    //
    // ────────────────────────────────────────────────── I ──────────
    //   :::::: P R O P S : :  :   :    :     :        :          :
    // ────────────────────────────────────────────────────────────
    //
    @Prop({ type: Object }) div!: appbase.cache.WebPartDiv;
    @Prop({type: String}) templateName!: string;
}