import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import WebpartBase from '@webparts/views/WebpartBase'
import {
  TotalBoardHeader,
  TotalBoardListArticle,
  TotalBoardImageArticle,
} from '../../components/index'
import api from '../../api/index'

@Component({
  components: {
    TotalBoardHeader,
    TotalBoardListArticle,
    TotalBoardImageArticle,
  },
})
export default class MyArticleWebpart extends WebpartBase {
  //
  // ──────────────────────────────────────────────── I ──────────
  //   :::::: D A T A : :  :   :    :     :        :          :
  // ──────────────────────────────────────────────────────────
  //

  activeBoard: any = {}
  nowPageNumber: number = 1
  totalCount!: number
  listArticles?: any = []
  boardDefaultImage: string = ''

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  TabClick(t: any) {
    if (this.activeBoard != t) {
      this.nowPageNumber = 1
      this.activeBoard = t
      this.getArticles()
    }
  }

  Prev() {
    if (this.nowPageNumber > 1) {
      this.nowPageNumber--
      this.getArticles()
    }
  }

  Next() {
    if (Math.ceil(this.totalCount / this.activeBoard.displayCount!) > this.nowPageNumber) {
      this.nowPageNumber++
      this.getArticles()
    }
  }

  Refresh() {
    this.nowPageNumber = 1
    this.getArticles()
  }

  load() {
    if (this.option.tabs != null && this.option.tabs.length > 0)
      this.activeBoard = this.option.tabs[0]

    this.getArticles()
  }

  async getArticles() {
    if (this.activeBoard.isImage) {
      this.boardDefaultImage = await api.webPart
        .getBoardDefaultImage(this.activeBoard.boardId)
        .then((m: any) => m)
    }

    var res = await api.webPart
      .getArticles(
        this.activeBoard.boardId,
        this.activeBoard.displayCount!,
        this.nowPageNumber,
        this.activeBoard.isContentShow,
        this.activeBoard.isNoticeShow
      )
      .then((m: any) => m)

    this.listArticles = res.data
    this.totalCount = res.totalCount
  }

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //

  mounted() {
    this.load()
  }
}
