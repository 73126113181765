import Vue from 'vue'
import Component from 'vue-class-component'

import apiClient from '@/api';
import * as appbaseTypes from '@appbase/store/types'
import { mapGetters, mapMutations } from 'vuex'
import { Prop, Watch } from 'vue-property-decorator'
import api from '@home/api'
import * as popupHelper from '@appbase/util/popup-helper'
import * as types from '@home/store/types'
import { ModalLayout } from '@appbase/templates'
import NoticeView from '@home/views/NoticePopup/NoticePreviewView.vue'
import { removeEventBus } from '@appbase/util'


const EumCookiePrefix = ".EumDoNotPopup_"


@Component({
    components: {
        ModalLayout,
        NoticeView
    },
    computed: {
        ...mapGetters({
            isTeams: appbaseTypes.TEAMS,
            modalPopupItem : types.NOTICEPOPUP_MODAL_GET
        }),
        
    },
    methods:{
        ...mapMutations({
            setTeams : appbaseTypes.TEAMS,
            setPopupItems: types.NOTICEPOPUP_SET,
            setModalPopupItem: types.NOTICEPOPUP_MODAL_SET
        })
    }
})
export default class PopupNotice extends Vue {
    //props
    @Prop({ type: String }) comCode!: string

    // mutations
    setPopupItems! : (data:Array<noticepopup.NoticeItem>) => void
    setModalPopupItem!: (data: noticepopup.ModalNoticeItem) => void
    setTeams! : (value:boolean) => void

    // getters
    isTeams!: boolean
    modalPopupItem! : noticepopup.ModalNoticeItem

    //data
    popupItems: Array<noticepopup.NoticeItem> = []
    NOTICE_PREVIEW_POPUP: string = '$notice/popup/preview';

    created() {
        //테스트 위해서 teams모드 변경
        // this.setTeams(true)
        //alert(`팝업알림 사용 : 팀즈사용 ${this.isTeams}`)
        this.initPopupNoticeData()
    }

    initPopupNoticeData() {
        api.home.getTodayNoticePopupList(this.comCode).then(async (res) => {
            if (res.length > 0) {
                //Ticker 에서 사용 할 수 있도록 전체 데이터 store set
                //일반 브라우저 인경우는 팝업까지 함께 표시
                //쿠키 확인해서 오늘 열지 않음 대상인지 확인한다
                var modalRes = await this.modalCookieCheck(res)
                
                this.setPopupItems(modalRes);

                if(modalRes.length > 0){
                    if (this.isTeams == false) {
                        //일반 브라우저 브라우저
                        modalRes.forEach(pop => {
                            this.popupNotice(pop)
                        })
                    } 
                    //팀즈인 경우네는 해당 popup을 스토어에 저장하고 종료
                    else{
                        this.setModalPopupItem({
                            ...modalRes[0],isShow:true
                        })
                    }
                }
            }
        });
    }

    /**
     * 팝업공지 쿠키 유효성 검사
     * 오늘의 팝업 목록 중 그만보기 처리해놓은 팝업이 있는지 쿠키로 검사
     * @param popupList 
     * @returns 
     */
    modalCookieCheck(popupList: noticepopup.NoticeItem[]){

        var pids = popupList.map(pop => pop.noticePopupId);
        return apiClient.post<number[]>({
            url: '/api/z/noticepopup/validate',
            data: pids
        }).then((pids: number[]) => {
            var rt :noticepopup.NoticeItem[] = popupList.filter(x => pids.includes(x.noticePopupId || 0) );
            return rt;
        });
    }

    /**
     * 웹 브라우저에서 팝업페이지를 엽니다.
     * @param popup 
     */
    popupNotice(popup: noticepopup.NoticeItem) {
        //일반 브라우저인경우 개별 팝업처리 

        popupHelper.openPopup(`noticePopup_${popup.noticePopupId}`, "/home/popup/notice",
            {},
            {
                width: popup.width,
                height: popup.height,
            },() => {
                removeEventBus(window, this.NOTICE_PREVIEW_POPUP, ()=>{});
            },
            {
                key: this.NOTICE_PREVIEW_POPUP,
                value: JSON.stringify(popup)
            },)
            
    }

    isShowModal(){
        if(this.modalPopupItem && this.isTeams == true){
            if(this.modalPopupItem.isShow == true) return true
        }

        return false
    }

    close(){
        this.setModalPopupItem({
            ...this.modalPopupItem,
            isShow : false
        })
    }

    setModalSize(){
        // width:600px;height:500px
        if(this.modalPopupItem && this.isTeams == true){
            return `width:${this.modalPopupItem.width}px;height:${this.modalPopupItem.height}px`
        }
        return ""
    }

    setModalTitle(){
        if(this.modalPopupItem && this.isTeams == true){
            return this.modalPopupItem.title
        }

        return ""
    }


}
 
