<template>
  <MasterTemplate>
    <router-view></router-view>
  </MasterTemplate>
</template>

<script>
import { MasterTemplate } from '@appbase/templates'
export default {
  components: { MasterTemplate },
}
</script>

<style></style>
