import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
// componemt
import { FullMasterTemplate } from '@/modules/@appbase/templates'
import SearchContainer from '@home/containers/SearchContainer/SearchContainer.vue'

@Component({
    components: {
        FullMasterTemplate,
        SearchContainer
    }
})
export default class SearchView extends Vue {
}