import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import * as accountTypes from '@account/store/types'

@Component({
    computed: mapGetters({
        user: accountTypes.CURRENT
    })
})
export default class MyInfoWebpart extends Vue {
    user!: account.TCurrent;

    getPhotoUrl(){
        if(this.user.user.photoPath && this.user.user.photoPath != ""){
            return this.user.user.photoPath
        }else{
            return "/cm/gres/faux/images/ghost.png"
        }
    }
}
