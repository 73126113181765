import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import * as dateutil from '@appbase/util/date-helper';
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue'

@Component({
    components:{ PageNavigationBar }
})
export default class RecentArticleList extends Vue {
    @Prop({ type:Array }) articleItems!: home.article.ArticleItem[]
    @Prop({ type:Number }) currentPage!: number
    @Prop({ type:Number }) totalPageCount!: number
    @Prop({ type:Number }) pageSize!: number
    @Prop({ type:String }) userLang!: string

    setDisplayDate(date: Date){
        var displayDate = new Date(date.toString());
        return dateutil.uGetDateFormat('yyyy-MM-DD', displayDate);
    }

    fnMovePage(number: number){
        this.$emit('onPaging', number);
    }

    async fnSearch(){
        let searchType = (<HTMLSelectElement>document.getElementById('ddlSearchType')).value;
        let searchText = (<HTMLInputElement>document.getElementById('txtSearchText')).value;

        if (searchText.trim() == ''){
            await (window as any).alertAsync(this.$t('M_BB_SearchIsNull').toString());
        }
        else{
            this.$emit('onSearch', searchType, searchText)
        }
    }
}