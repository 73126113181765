import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
 
import { ModalLayout, PageLayout } from '@appbase/templates'
import GlobalNavigationBar from '@gnb/views/GlobalNavigationBar/GlobalNavigationBar.vue'
import * as typesAccount from '@account/store/types'

@Component({
  components: { ModalLayout, PageLayout,  GlobalNavigationBar },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class BaseLayout extends Vue {
  current!: account.TCurrent
}
