import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import SubContentTemplate from '@appbase/templates/SubContentTemplate.vue'
import PageNavigationBar from '@appbase/components/Paginate/PageNavigationBar.vue';
import { MypageHeader, MypageList } from '@home/components/MyPage'
import api from '@home/api/index'
import { createEventBus, removeEventBus } from '@appbase/util/eventBus'

@Component({
    components: { SubContentTemplate, PageNavigationBar, MypageHeader, MypageList },
})
export default class MypageContainer extends Vue {
    @Prop({type:Number, default:0}) listType!:number

    pageNumber:number = 1;
    rowCount:number = 10;
    totalCount:number =0;
    keyword:string = '';
    mypageArticles:Array<mypage.mypageArticle>=[]
    title :string ="내_게시글"
    isLoading:boolean = false;

    load(){
        this.isLoading=true;
        api.mypage.getMypageArticle({
            pageNumber:this.pageNumber,
            rowCount:this.rowCount,
            keyword:this.keyword,
            requestType:this.listType
        }).then(res=>{
            this.mypageArticles = res.articles
            this.totalCount = res.totalCount
            this.isLoading=false
        })
    }

    pageMove(num:number){
        this.pageNumber = num
        this.load();
    }

    searchClick(val:string){
        this.keyword = val
        this.load();
    }

    reset(){
        this.keyword=''
        this.load();
    }

    @Watch('listType')
    reloadList(newVal:number){
        this.title = newVal==0?'내_게시글':newVal==1?'내가_작성한_댓글':'최근_조회_게시글'
        this.pageNumber = 1
        this.keyword=''
        this.load();
    }

    created(){
        this.load()
        createEventBus(window, 'boardlist-reload', this.load)
    }
    beforeDestroy() {
        removeEventBus(window, 'boardlist-reload', this.load);
    }
    
}
