import Vue from 'vue'
import Component from 'vue-class-component'
import { mapGetters } from 'vuex'
import { MenuPageTypes } from '@appbase/types/enum'
import * as typesAccount from '@account/store/types'
import menuHelper from '@appbase/util/menu-helper'
import { Watch } from 'vue-property-decorator'
// component
import { ModalLayout, PageLayout } from '@appbase/templates'
 
import GlobalNavigationBar from '@gnb/views/GlobalNavigationBar/GlobalNavigationBar.vue'
import { LeftMenu } from '@appbase/components/common'
import ContentComponent from '../components/Proxy/ContentComponent.vue'
import { FullMasterTemplate } from '@/modules/@appbase/templates'

@Component({
  components: { ModalLayout, PageLayout,  GlobalNavigationBar, LeftMenu, ContentComponent, FullMasterTemplate },
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class ProxyView extends Vue {
  current!: account.TCurrent

  //
  // ────────────────────────────────────────────────────────────── I ──────────
  //   :::::: L O C A L   S T A T E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────────
  //

  menuItem: appbase.cache.TMenuItem | null = null
  isUseLeftMenu: boolean = false

  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //

  fnSetMenuItem() {
    const { MENUID } = this.$route.query
    let mItem = menuHelper.findOne(Number(MENUID));
    if (mItem){
      if (!mItem.targetUrl && mItem.hasChildren && mItem.children.length > 0) {
        mItem = menuHelper.findOne(mItem.children[0].menuId);
      }

      if(mItem?.pageType){
        this.isUseLeftMenu = ([MenuPageTypes.LW, MenuPageTypes.LE].includes(mItem.pageType) ? true : false)
      }
      else{
        this.isUseLeftMenu = true;
      }
      
      this.menuItem = mItem;
    }
    else {
      throw new Error('[NOT_FOUND_MENU_ERROR] : ' + MENUID)
    }
  }

  @Watch('$route.query.MENUID')
  changeMenuId() {
    this.fnSetMenuItem()
  }

  created() {
    this.fnSetMenuItem()
  }
}
