import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { WebpartContainer } from '../containers'
import api from '../api/index'
import { mapGetters } from 'vuex'
import * as typesAccount from '@account/store/types'
import { FullMasterTemplate } from '@/modules/@appbase/templates'
 
import  PopupNotioce  from '@home/components/PopupNotice/PopupNotice.vue'
import AttendanceSideBar from '@home/components/Attendance/AttendanceSideBar.vue'
import FixedWebpartContainer from '@home/containers/FixedWebpartContainer/FixedWebpartContainer.vue'
 
 

@Component({
  components: {WebpartContainer,  FullMasterTemplate,   AttendanceSideBar,FixedWebpartContainer, PopupNotioce},
  computed: {
    ...mapGetters({
      current: typesAccount.CURRENT,
    }),
  },
})
export default class HomeView extends Vue {
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: G E T T E R S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  current!: account.TCurrent

  isEnablePopupNotice : boolean  = false

  useAttendance : boolean = false
  //
  // ────────────────────────────────────────────────────── I ──────────
  //   :::::: M E T H O D S : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────
  //
  

  //
  // ────────────────────────────────────────────────────────── I ──────────
  //   :::::: L I F E S T Y L E : :  :   :    :     :        :          :
  // ────────────────────────────────────────────────────────────────────
  //
  mounted() {
    //팝업 알림 대상 
    //App 설정으로 팝업 사용유무 확인 후 component init
    const popupConfig = this.current.appConfig.filter(config =>{
//      key:"UsePopupNotice"
        return config.key.toLowerCase() == "usepopupnotice"
    })
    if(popupConfig.length > 0){
      if(popupConfig[0].value.toLowerCase() == "true"){
        this.isEnablePopupNotice = true
      }
    } 
    //this.load()
  }

  created() {
    const body = document.querySelector('body')
    if (body) {
      body.className = 'bg-body-basic'
    }

    //근퇴 관리 
    //C021 (한국법인만 사용)
    let coperationCode = this.current.user.personCode.split("_")[0]

    if(coperationCode === 'C021') this.useAttendance = true

    if(coperationCode === 'C121' && !this.current.user.deptCode.startsWith("D12120") && !this.current.user.deptCode.startsWith("D12130") ) this.useAttendance = true

  }

  beforeDestroy() {
    const body = document.querySelector('body')
    if (body) {
      body.className = ''
    }
  }
}
