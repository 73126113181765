import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { mapGetters } from 'vuex';
import * as usertypes from '@account/store/types';

@Component({
    computed: mapGetters({
        userCurrent: usertypes.CURRENT
    })
})
export default class SearchForm extends Vue {
    @Prop({type:String}) searchText!: string
    @Prop({type:Number}) totalCount!: number
    @Prop({type:String}) userLang!: string   
    userCurrent!: account.TCurrent;
    arrCurrentSearch: string[] = []

    async fnTotalSearch(){
        let searchText = (<HTMLInputElement>document.getElementById("txtSearchText")).value
        if (searchText.length){
            this.fnCurrentSearch(searchText);
            this.$emit("onTotalSearch", searchText, '');
        }
        else{
            await (window as any).alertAsync(this.$t('검색어_입력').toString());
            (<HTMLInputElement>document.getElementById("txtSearchText")).focus()
        }
    }

    fnCurrentClick(cur: string){
        if (cur){
            this.fnCurrentSearch(cur);
            this.$emit("onTotalSearch", cur, '');
        }
    }

    fnCurrentSearch(value: string){
        // 최근검색어 표시
        let currentSearch = localStorage.getItem("eum.TotalSearch.Current_" + this.userCurrent.user.personCode)
        if (currentSearch){
            this.arrCurrentSearch = [];
            let arrSearch = currentSearch.split('|');
            let dispCount = (arrSearch.length > 10 ? 10 : arrSearch.length);
            for (let i = 0; i < dispCount; i++){
                this.arrCurrentSearch.push(arrSearch[i]);
            }
        }
        
        if (value){
            let nIdx = this.arrCurrentSearch.findIndex(x => x == value);
            if (nIdx  > -1){
                this.arrCurrentSearch.splice(nIdx, 1);
            }
            if (this.arrCurrentSearch.length > 9) this.arrCurrentSearch.splice(this.arrCurrentSearch.length - 1, 1);
            this.arrCurrentSearch.unshift(value);
            localStorage.setItem("eum.TotalSearch.Current_" + this.userCurrent.user.personCode, this.arrCurrentSearch.join('|'));
        }
    }

    created(){
        this.fnCurrentSearch(this.searchText);
    }
}