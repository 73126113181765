import Vue from 'vue'
import { Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex'
import api from '@home/api'
import boardApi from '@board/api'
import * as usertypes from '@account/store/types';
import {open, OpenType} from '@webparts/util/open-handler'
import ZinusBoardModal from "@webparts/views/Zinus/Modal/ZinusBoardModal.vue"
import modalHelper from '@appbase/util/modal-helper';

@Component({
    computed: {
        ...mapGetters({
            user: usertypes.USER
        })
    },
})
export default class NoticeTicker extends Vue {
    //
    // ──────────────────────────────────────────────── I ──────────
    //   :::::: D A T A : :  :   :    :     :        :          :
    // ──────────────────────────────────────────────────────────
    // 
    user!: account.TUser
    items : Array<home.tickerItem> = []
    timer : any = null
    interval : number = 3000
    showIndex : number = 0
    displayCount : number = 5 

    created(){
       
        this.initTickerData()

        this.timer = setInterval(()=>{
           
            this.showIndex++
            if(this.showIndex == (this.items.length)) this.showIndex = 0

        },this.interval)
    }

    initTickerData(){
        //법인 게시판 아이디 부터 먼저 조회
 
        boardApi.board.FindCoperationBoard().then(result=>{
            let myCoperation = result.find(co => {
                //이전에는 C021 였는데 부서 데이터 변경하면서 
                //CoperationCode 가 D 로 시작함 조회값에서 D 를 C 로 변경함
                return co.coperationCode.replace("D","C") == this.user.personCode.split("_")[0]
                
            })
            if (myCoperation) {
                api.board.getTickers({
                    boardId : myCoperation.boardId,
                    displayCount : this.displayCount
                }).then(tickerResult=>{
                    this.items = tickerResult
                })
            }else{
                this.items = []
            }
        })
        
    }

     

    
    onClickedItem(boarId: number, articleId: number, title:string) {
        //alert(`board id : ${boarId}\narticle id : ${articleId}\ntype : ${this.isTeams ? 'modaltype' : 'popuptype'}`)
        if(this.isTeams){
            modalHelper.show({ component: ZinusBoardModal, force: true, value: { boardId: boarId, articleId: articleId, title:title } });
        }else{
            open(this.isTeams ? 
                OpenType.ModalRead : OpenType.PopupRead,
                {
                    boardId: boarId,
                    articleId: articleId,
                    baseUrl: (process.env.NODE_ENV === 'production' ? '/po' : '/') + "/BoardPopup/"
                });
        }
    
    }

}
